import { lazy, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ReactComponent as IconInfo } from '@/assets/images/icons/icon-info.svg';
import psbBannerDesktop from '@/assets/images/psb-banner-desktop.webp';
import psbBannerMobile from '@/assets/images/psb-banner-mobile.webp';

import PartnerInfo from '@/components/Modals/PartnerInfo/PartnerInfo';

import styles from './Main.module.scss';

const Header = lazy(() => import('@/components/Header/Header'));
const Support = lazy(() => import('@/components/Support'));
const Head = lazy(() => import('@/components/Head/Head'));
const About = lazy(() => import('@/components/About/About'));
const StatLines = lazy(() => import('@/components/StatLines/StatLines'));
const Features = lazy(() => import('@/components/Features/Features'));
const Formats = lazy(() => import('@/components/Formats/Formats'));
const Maps = lazy(() => import('@/components/Maps/Maps'));
const StartPack = lazy(() => import('@/components/StartPack/StartPack'));
const Engraving = lazy(() => import('@/components/Engraving/Engraving'));
const FunZone = lazy(() => import('@/components/FunZone/FunZone'));
const Activities = lazy(() => import('@/components/Activities/Activities'));
const OnerunCountries = lazy(() => import('@/components/OnerunCountries/OnerunCountries'));
const RunningCapital = lazy(() => import('@/components/RunningCapital/RunningCapital'));
const Medical = lazy(() => import('@/components/Medical/Medical'));
const Limits = lazy(() => import('@/components/Limits/Limits'));
// const Results = lazy(() => import('@/components/Results/Results'))
const Faq = lazy(() => import('@/components/Faq/Faq'));
const JoinTeam = lazy(() => import('@/components/JoinTeam/JoinTeam'));
const Partners = lazy(() => import('@/components/Partners/Partners'));
const Record = lazy(() => import('@/components/Record/Record'));
const Footer = lazy(() => import('@/components/Footer/Footer'));

const mainPartnerDesk = {
  adInfo: {
    company: 'ПАО "Промсвязьбанк"',
    inn: 7744000912,
    token: '2VtzqwsTdWL',
  },
};

const mainPartnerMobi = {
  adInfo: {
    company: 'ПАО "Промсвязьбанк"',
    inn: 7744000912,
    token: '2VtzqupsHmn',
  },
};

function Main({ onChangeCity }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isOpenPartnerInfo, setIsOpenPartnerInfo] = useState(false);

  return (
    <>
      <Header onChangeCity={onChangeCity} />
      <Head />
      <About />
      <StatLines />
      <Features />
      <Formats onChangeCity={onChangeCity} />
      <Maps />
      <StartPack />
      {/* <div className={styles.psbBannerWrap}>
          <div className={styles.psbBannerInner}>
            <a
              href="https://ib.psbank.ru/store/products/your-cashback-new?LG=111042165&utm_source=zabeg_rf&utm_medium=cpm&utm_campaign=banner_rb_2380_460_image&utm_content=5"
              target="_blank" rel="noreferrer noopener"
            >
              {isMobile ? (
                <img className={styles.psbBanner} width={280} height={309} src={psbBannerMobile} alt="" />
              ):(
                <img className={styles.psbBanner} width={1280} height={247} src={psbBannerDesktop} alt="" />
              )}
            </a>

            <button className={styles.btnInfo} onClick={() => setIsOpenPartnerInfo(true)}>
              <span className="visually-hidden">Информация о партнере</span>
              <IconInfo width={20} height={20} />
            </button>
          </div>
        </div> */}
      {/* <Engraving /> */}
      <FunZone />
      <Activities />
      {/* <OnerunCountries /> */}
      <RunningCapital />
      <Medical />
      <Limits />
      {/* <Results /> */}
      <JoinTeam />
      <Support />
      <Faq />
      <Partners />
      <Record />
      <Footer />

      <PartnerInfo
        partner={isMobile ? mainPartnerMobi : mainPartnerDesk}
        isOpen={isOpenPartnerInfo}
        onClose={() => setIsOpenPartnerInfo(false)}
      />
    </>
  );
}

export default Main;
